import React, { useState, useContext } from 'react';
import PageContext from '../../../hooks/pageContextProvider';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import Icon from '../../Atoms/Icon/Icon';
import {
  SocialShareWrapper,
  SocialShareText,
  SocialShareList,
  CopyButton,
  HelpTextBubble,
} from './styles';

const SocialShare = ({ shareUrl, shareTitle }) => {
  const { microCopy } = useContext(PageContext);
  const [isTextCopied, setIsTextCopied] = useState(false);

  async function copyTextToClipboard(url: string) {
    try {
      if (navigator?.clipboard?.writeText) {
        await navigator.clipboard.writeText(url);
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(shareUrl)
      .then(() => {
        setIsTextCopied(true);
        setTimeout(() => {
          setIsTextCopied(false);
        }, 1000);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <SocialShareWrapper>
      <SocialShareText>{`${microCopy?.resourcesShareOn}`}</SocialShareText>
      <SocialShareList>
        <li>
          <LinkedinShareButton
            url={shareUrl}
            title={shareTitle}
            resetButtonStyle={false}
            aria-label="LinkedIn"
          >
            <Icon icon="LinkedIn" size={20} />
          </LinkedinShareButton>
        </li>
        <li>
          <FacebookShareButton
            url={shareUrl}
            title={shareTitle}
            resetButtonStyle={false}
            aria-label="Facebook"
          >
            <Icon icon="Facebook" size={20} />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton
            url={shareUrl}
            title={shareTitle}
            resetButtonStyle={false}
            aria-label="Twitter"
          >
            <Icon icon="Twitter" size={20} />
          </TwitterShareButton>
        </li>
        <li>
          <CopyButton
            onClick={handleCopyClick}
            aria-label={`${microCopy?.resourcesCopied}`}
          >
            <Icon icon="CopyLink" size={20} />
          </CopyButton>
        </li>
      </SocialShareList>
      {isTextCopied && (
        <HelpTextBubble>{`${microCopy?.resourcesCopied}`}</HelpTextBubble>
      )}
    </SocialShareWrapper>
  );
};

export default SocialShare;
