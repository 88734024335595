import styled from 'styled-components';
import themeGet from '../../../style/themeGet';
import { desktopOnly } from '../../../style/desktopHelpers';

export const SocialShareList = styled.ul`
  display: flex;
  list-style: none;
  gap: ${themeGet('spacing.s6')};
`;

export const SocialShareWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${desktopOnly} {
    justify-content: flex-end;
    width: 50%;
  }
  button {
    cursor: pointer;
    height: 32px;
    width: 32px;
    border-radius: 100%;
    border: 1px solid ${themeGet('color.primary.graphite')};
    color: ${themeGet('color.primary.white')};
    background-color: ${themeGet('color.primary.graphite')};
    display: flex;
    align-items: center;
    justify-content: center;
    outline-color: ${themeGet('color.primary.white')};
  }
  svg {
    path {
      fill: ${themeGet('color.primary.white')};
    }
  }
`;

export const CopyButton = styled.button``;

export const SocialShareText = styled.span`
  display: flex;
  align-items: center;
  font-size: 1.8rem;
  line-height: 2.2rem;
  white-space: nowrap;
  ${desktopOnly} {
    margin-right: ${themeGet('spacing.s6')};
  }
`;

export const HelpTextBubble = styled.span`
  position: absolute;
  color: ${themeGet('color.primary.white')};
  background: ${themeGet('color.primary.graphite')};
  border-radius: 0.4em;
  padding: 4px;
  top: 75px;
  right: -3%;
  z-index: 1;

  ${desktopOnly} {
    right: 11.5%;
    top: 75px;
  }
  @media ((min-width: 555px) and (max-width: 1006px)) {
    right: -1.5%;
    top: 75px;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    height: 0;
    border-width: 0 9px 14px;
    border-style: solid;
    border-color: transparent;
    border-bottom-color: ${themeGet('color.primary.graphite')};
    border-top: 0;
    margin-left: -8px;
    margin-top: -10px;
  }
`;
