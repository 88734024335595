import { useEffect, useState } from 'react';

const useStructuredData = (rawData) => {
  const [structuredData, setStructuredData] = useState(null);

  useEffect(() => {
    if (rawData) {
      try {
        const parsedData = JSON.parse(rawData);
        const value = parsedData?.content?.[0]?.content?.[0]?.value;
        setStructuredData(value);
      } catch (e) {
        console.error(e);
      }
    }
  }, [rawData]);

  return structuredData;
};

export default useStructuredData;
