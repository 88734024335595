import React, {useEffect, useState} from 'react';

import {IGatsbyImageData} from 'gatsby-plugin-image';
import styled from 'styled-components';

import {renderRichTextNode} from '../../../hooks/useRichText';
import colorTheme from '../../../style/colorTheme';
import {desktopOnly} from '../../../style/desktopHelpers';
import themeGet from '../../../style/themeGet';
import ContentfulImage from '../../Atoms/ContentfulImage/ContentfulImage';
import {H2, H4} from '../../Atoms/Typography/Typography';

interface CaseStudyHeaderProps {
    id: string;
    type: string;
    primaryText?: string;
    introText?: {
        raw: string;
    };
    secondaryText?: string;
    bodyText?: {
        raw: string;
    };
    featureImage?: IGatsbyImageData;
    secondaryImage?: IGatsbyImageData;
    theme?: string;
}

const CaseStudyHeaderContainer = styled.div`
    ${({ezratheme}) => colorTheme(ezratheme)};

    grid-column: 1 / 8;
    position: relative;
    display: flex;
    padding-top: 40px;

    ${desktopOnly} {
        grid-column: 3 / span 5;
        padding-bottom: ${themeGet('spacing.s6')};
    }
`;

const StyledImageContainer = styled.div`
    margin: 0 0 32px;

    img {
        max-height: 75px;
        height: 75px;
        width: 100%;
    }
`;

const StyledH2 = styled(H2)`
    color: var(--highlight);
    margin-bottom: ${themeGet('spacing.mobile.s5')};

    ${desktopOnly} {
        margin-bottom: ${themeGet('spacing.s5')};
    }
`;

const StyledH4 = styled(H4)`
    color: var(--primary);
    margin-bottom: 40px;
`;

const Background = styled.div`
    background-color: var(--background);
    position: absolute;
    top: 0;
    left: -100vw;
    width: 200vw;
    height: 100%;
    overflow: hidden;
    z-index: 0;
`;

const LiftedDiv = styled.div`
    z-index: 1;
`;

const CaseStudyHeader = ({
                             primaryText,
                             secondaryImage,
                             introText,
                             theme,
                         }: CaseStudyHeaderProps) => {
    return (
        <CaseStudyHeaderContainer
            ezratheme={theme === 'EZRAxCampaign' ? 'EZRAx-Campaign-Dark' : theme === "EZRAFocus" ? 'EZRAFocus-Campaign-Light-Reversed' : 'Dark'}
        >
            <Background/>
            <LiftedDiv>
                {secondaryImage && (
                    <StyledImageContainer>
                        <ContentfulImage image={secondaryImage?.[0]}/>
                    </StyledImageContainer>
                )}
                <StyledH2 as="h1">{primaryText}</StyledH2>
                <StyledH4>{introText?.raw && renderRichTextNode(introText)}</StyledH4>
            </LiftedDiv>
        </CaseStudyHeaderContainer>
    );
};

export default CaseStudyHeader;
