import React from 'react';

import styled from 'styled-components';

import { renderRichTextNode } from '../../../hooks/useRichText';
import colorTheme from '../../../style/colorTheme';
import { desktopOnly } from '../../../style/desktopHelpers';
import themeGet from '../../../style/themeGet';
import { ITheme } from '../../../types/types';
import ContentfulImage from '../../Atoms/ContentfulImage/ContentfulImage';
import { Caption, H3, H4, B2 } from '../../Atoms/Typography/Typography';

export const CustomerBioContainer = styled.div<ITheme>`
  ${({ ezratheme }) => colorTheme(ezratheme)};
  background-color: var(--background);
  display: grid;
  position: relative;
  grid-column: 1 / -1;
  padding: ${themeGet('spacing.mobile.s4')} 0;

  ${desktopOnly} {
    grid-column: 3 / span 5;
    width: 100%;
    padding: ${themeGet('spacing.s4')} 0;
  }
`;

export const StyledContainer = styled.div`
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;
export const StyledH3Container = styled.div`
  grid-column: 1 / -1;
  margin-bottom: ${themeGet('spacing.s6')};

  ${desktopOnly} {
    display: flex;
    grid-column: 5 / 8;
    align-items: center;
  }
`;

export const StyledH4CaptionContainer = styled.div`
  padding-top: ${themeGet('spacing.mobile.s6')};
  padding-bottom: ${themeGet('spacing.mobile.s4')};

  ${desktopOnly} {
    padding-bottom: 0;
    padding-top: ${themeGet('spacing.s6')};
  }
`;

export const TestimonialContainer = styled.div`
  margin-top: ${themeGet('spacing.mobile.s1')};

  ${desktopOnly} {
    margin-top: ${themeGet('spacing.s4')};
  }
`;

export const StyledB2 = styled(B2)`
  color: var(--primary);
`;

export const StyledH3 = styled(H3)`
  color: var(--primary);

  ${desktopOnly} {
    padding-left: 24px;
    width: 60%;
  }
`;

export const StyledH4 = styled(H4)`
  color: var(--highlight);
  margin-bottom: 8px;
`;

export const StyledCaption = styled(Caption)`
  color: var(--primary);
`;

export const ImageContainer = styled.div`
  img {
    object-fit: cover;
    width: 100%;
    height: 315px;
  }

  ${desktopOnly} {
    width: 40%;
    img {
      object-fit: cover;
      width: 100%;
      height: 285px;
    }
  }
`;

const Background = styled.div`
  background-color: var(--background);
  position: absolute;
  top: 0;
  left: -100vw;
  width: 200vw;
  height: 100%;
  overflow: hidden;
  z-index: 0;
`;

const CustomerBio = ({
  appearance,
  primaryText,
  secondaryText,
  featureImage,
  bodyText,
  testimonial,
}) => {
  return (
    <CustomerBioContainer ezratheme={appearance?.theme || 'Midtone'}>
      <Background />
      <StyledContainer>
        <ImageContainer>
          <ContentfulImage image={featureImage?.[0]} />
          <StyledH4CaptionContainer>
            <StyledH4>{primaryText}</StyledH4>
            <StyledCaption>{secondaryText}</StyledCaption>
          </StyledH4CaptionContainer>
        </ImageContainer>

        <StyledH3>{bodyText?.raw && renderRichTextNode(bodyText)}</StyledH3>

        <TestimonialContainer>
          <StyledB2>{testimonial && renderRichTextNode(testimonial)}</StyledB2>
        </TestimonialContainer>
      </StyledContainer>
    </CustomerBioContainer>
  );
};

export default CustomerBio;
