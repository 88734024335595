import styled from 'styled-components';

import colorTheme from '../../../style/colorTheme';
import { desktopOnly } from '../../../style/desktopHelpers';
import themeGet from '../../../style/themeGet';
import { ITheme } from '../../../types/types';

export const HeroContainer = styled.div<ITheme>`
  ${(props) => colorTheme(props.ezratheme)};
  background-color: var(--background);
  color: var(--primary);
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-column: 3 / span 5;
  margin-bottom: 32px;
  max-height: 486px;
  ${desktopOnly} {
    min-height: 256px;
  }
`;

export const VideoImageContainer = styled.div`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
  display: flex;
  max-height: 810px;

  ${desktopOnly} {
    grid-column: 1 / -1;
    grid-row: 1 / -1;
    max-width: 1440px;
    margin: 0 auto;
    position: relative;
    display: flex;
    max-height: 486px;
  }

  .hosted-player,
  .vimeo-player {
    align-self: flex-end;
    ${desktopOnly} {
      max-height: 486px !important;
    }
    video {
      object-position: center bottom;
      ${desktopOnly} {
        max-height: 486px !important;
      }
    }

    .video {
      ${desktopOnly} {
        max-height: 486px !important;
      }
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  @media (min-width: ${themeGet('breakpoints.mobile')}) {
    .gatsby-image-wrapper img {
      object-position: bottom center !important;
    }
  }
`;

export const ContentContainer = styled.div<{ align: string }>`
  grid-column: 1 / -1;
  grid-row: 1 / -1;
  padding: ${themeGet('spacing.mobile.s1')} 0;
  position: relative;
  z-index: 10;
  display: flex;
  ${(props) => (props.align === 'centre' ? `align-items: center;` : ``)}

  .hero-title {
    margin-bottom: ${themeGet('spacing.s5')};
    line-height: 1;
    max-width: 60%;
  }

  ${desktopOnly} {
    padding: ${themeGet('spacing.s3')} 0;

    .hero-cta {
      font-size: ${themeGet('font.size.b2')};
    }
  }
`;

export const PlayButton = styled.button`
  background-color: transparent;
  border: none;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  position: absolute;
  bottom: 30px;
  right: 30px;
  z-index: 100;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover,
  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.4);
  }

  ${desktopOnly} {
    bottom: 40px;
    right: 40px;
  }
`;
