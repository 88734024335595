import React, { useContext } from 'react';

import { HeroContainer, VideoImageContainer, ContentContainer } from './styles';
import PageContext from '../../../hooks/pageContextProvider';
import { getLocalePage } from '../../../hooks/useLocale';
import { renderRichTextNode } from '../../../hooks/useRichText';
import { IFlexibleComponent } from '../../../types/types';
import { ButtonLink } from '../../Atoms/Button/Button';
import { B2, H1SM } from '../../Atoms/Typography/Typography';
import MediaWrapper from '../../Molecules/MediaWrapper/MediaWrapper';
import Wrapper from '../../Wrapper/Wrapper';

const ResourceHeroBanner = ({
  content,
  appearance,
  alignment,
}: IFlexibleComponent) => {
  const video = content?.video;
  const { locale } = useContext(PageContext);

  return (
    <HeroContainer ezratheme={appearance?.theme || 'Grey'}>
      <VideoImageContainer>
        <MediaWrapper isResource video={video} image={content?.featureImage} />
      </VideoImageContainer>
      {(content.primaryText || content?.bodyText) && (
        <ContentContainer align={alignment}>
          <Wrapper>
            {content?.primaryText && (
              <H1SM className="hero-title">{content?.primaryText}</H1SM>
            )}
            {content?.bodyText?.raw && (
              <B2 as="div">{renderRichTextNode(content.bodyText)}</B2>
            )}
            {content?.link?.[0] && (
              <ButtonLink
                to={getLocalePage(content.link?.[0], locale)}
                variant="primary"
                size="small"
                className="hero-cta"
                ezratheme={appearance?.theme || 'Grey'}
              >
                {content?.link?.[0]?.text}
              </ButtonLink>
            )}
          </Wrapper>
        </ContentContainer>
      )}
    </HeroContainer>
  );
};

export default ResourceHeroBanner;
