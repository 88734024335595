import React, { useCallback, useEffect, useRef } from 'react';

import { Button } from '../../Atoms/Button/Button';

declare global {
  interface Window {
    EBWidgets: any;
  }
}

const EVENT_BRITE_SCRIPT_SRC =
  'https://www.eventbrite.ca/static/widgets/eb_widgets.js';

interface EventBriteButtonProps {
  eventId?: string;
  label?: string;
}

const EventBriteButton = (props: EventBriteButtonProps) => {
  const { eventId, label } = props;
  const EventBriteButtonRef = useRef<HTMLButtonElement>(null);

  const setupEventBriteModal = useCallback(() => {
    if (eventId && EventBriteButtonRef.current) {
      window.EBWidgets.createWidget({
        widgetType: 'checkout',
        eventId: eventId,
        modal: true,
        modalTriggerElementId: EventBriteButtonRef.current.id,
      });
    }
  }, [eventId, EventBriteButtonRef]);

  useEffect(() => {
    let script = document.querySelector<HTMLScriptElement>(
      `script[src="${EVENT_BRITE_SCRIPT_SRC}"]`,
    );

    if (!script) {
      // Create script
      script = document.createElement('script');
      script.src = EVENT_BRITE_SCRIPT_SRC;
      script.async = true;

      // Add script to document body
      document.body.appendChild(script);
    } else {
      // script already inserted
    }

    // Add event listeners
    const onLoadScript = () => {
      setupEventBriteModal();
    };

    script.addEventListener('load', onLoadScript);

    return () => {
      if (script) {
        script.removeEventListener('load', onLoadScript);
      }
    };
  }, []);

  return (
    <Button
      id={`eventbrite-widget-modal-trigger-${eventId}`}
      variant="primary"
      ref={EventBriteButtonRef}
    >
      {label}
    </Button>
  );
};

export default EventBriteButton;
